<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.orders')}}
                                        <span v-if="pagination.total">({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('archiveorderview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                      variant="outline-info"
                                                      @click="handleExcelExportClick" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('archiveorderview')">
                                                <i class="fa fa-file-excel-o"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="archives-table">
                    <b-table hover responsive
                             ref="archiveTable"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(order_id)="{ detailsShowing, item, index, rowSelected }">
                            <template v-if="rowSelected">
                                <span aria-hidden="true">&check;</span>
                            </template>
                            <a @click="toggleDetails(item)" class="d-inline in-center">
                                <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                            </a>
                            {{item.order_id}}
                        </template>
                        <template v-slot:cell(brand_id)="{ detailsShowing, item, index, rowSelected }">
                            <a-avatar shape="square" :size="32" icon="car"
                                      :title="item.brand ? item.brand.title : ''"
                                      v-if="item.brand.logo && item.brand.logo.download_url"
                                      :src="item.brand.logo.system_url+'/thumbnail/32x32/'+item.brand.logo.name2"/>
                            <a-avatar shape="square" :size="32" icon="car"
                                      :title="item.brand ? item.brand.title : ''" v-else/>
                            <span class="indicator-added-inbound-load"
                                  v-if="item.load_status < 7 && item.added_in_load_at"
                                  :title="$t('title.inboundAdded')" v-b-tooltip.hover></span>
                            <span class="indicator-added-outbound-load"
                                  v-if="item.load_status >= 10 && item.load_status < 17"
                                  :title="$t('title.outboundAdded')" v-b-tooltip.hover></span>
                        </template>
                        <template v-slot:cell(client_id)="record">
                            {{ (record.item.client ? record.item.client.company_name : '') }}
                        </template>
                        <template v-slot:cell(load_status)="{item}">
                            <ol-status :status="item.load_status"></ol-status>
                            <div v-if="item.has_call_of_dd">
                                <tr-status :status="item.tr_status" :type="item.tr_status_type"></tr-status>
                            </div>
                        </template>
                        <template v-slot:cell(from_location_id)="{item}">
                            <print-ad titled="1" :item="item.from_location"></print-ad>
                        </template>
                        <template v-slot:cell(to_location_id)="{item}">
                            <print-ad titled="1" :item="item.to_location"></print-ad>
                        </template>
                        <template v-slot:cell(dealer_id)="{item}">
                            {{(item.dealer || {}).name}}
                        </template>
                        <template v-slot:cell(model)="record">
                            {{record.item.model ? record.item.model.title : ""}}
                        </template>
                        <template v-slot:cell(manufacturer_invoice_date)="record">
                            {{record.item.manufacturer_invoice_date ?
                            $global.utcDateToLocalDate(record.item.manufacturer_invoice_date, 'DD.MM.YYYY') : '' }}
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(logistic_type)="record">
                                <span class="text-capitalize">
                                    <span
                                        v-if="record.item.tr_status_type == 'outbound'">{{$t('title.outbound')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'reexport'">{{$t('title.reexport')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'dd'">D2D</span>
                                    <span v-if="record.item.tr_status_type == 'cc'">CC</span>

                                </span>
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('archiveorderview') && $global.hasAnyRole(['superadmin', 'efl'])"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item><strong>{{$t('column.model')}}</strong>:
                                        {{(item.model || {}).title}}
                                    </b-list-group-item>
                                    <b-list-group-item><strong>{{$t('column.comments')}}</strong>:
                                        {{item.comments}}
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.archives-table -->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                            @input="handleSelectBrands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <fieldset aria-describedby="" class="form-group">
                                        <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
                                            <b-row>
                                                <b-col sm="6">
                                                    {{$t('input.models')}}
                                                </b-col>
                                                <b-col sm="6" class="text-right">
                                                    <label v-show="filters.brands.length > 0" class="m-0 p-0">
                                                        <input
                                                            v-model="selectAllBrand"
                                                            type="checkbox"
                                                            @change="handleSelectAllModels"/>
                                                        {{$t('input.selectAll')}}
                                                    </label>
                                                </b-col>
                                            </b-row>
                                        </legend>
                                        <treeselect
                                            :multiple="true"
                                            :options="_.filter(dropdowns.modelsGroupByTitle, (item) => _.includes(filters.brands, item.brand_id))"
                                            placeholder=""
                                            v-model="filters.modelsGroupByTitle"
                                            @input="handleSelectModels"
                                        />
                                    </fieldset>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options="_.filter(dropdowns.configCodes, (item) => _.includes(filters.modelsGroupByTitle, item.model_label))"
                                            placeholder=""
                                            v-model="filters.configCodes"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.clients')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.clients"
                                            placeholder=""
                                            v-model="filters.clients"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.dealers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="_.filter(dropdowns.dealers,(i) => i.id = i.label)"
                                            placeholder=""
                                            v-model="filters.dealers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromLocations')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.fromLocations"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toLocations')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.toLocations"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.dateOfAvailability')">
                                        <b-form-datepicker placeholder="" reset-button
                                                           v-model="filters.manufacturer_invoice_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="Orders"
                            :fetch="handleExcelExportSubmitClick"
                            name="orders.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from "./detail"
    import QuickSupplierForm from "../../suppliers/QuickSupplierForm";
    import QuickLocationForm from "../../locations/QuickLocationForm";
    import {request} from "../../../util/Request";
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        modelsGroupByTitle: [],
        configCodes: [],
        dealers: [],
        clients: [],
        status: [],
        fromLocations: [],
        toLocations: [],
        manufacturer_invoice_date: null,
        is_manual: [0],
        has_manufacturer_invoice_date: 1,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.orderId'),
            key: 'order_id',
            sortable: true,
            stickyColumn: true,
        },
        {
            label: self.$t('column.brand'),
            key: 'brand_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.dealer'),
            key: 'dealer_id',
            sortable: true,
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.client'),
            key: 'client_id',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.logisticType'),
            key: 'logistic_type',
            sortable: false,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['archiveorderview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right w-100px',
                key: 'action',
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, ExcelExportMixin],
        components: {
            Datepicker,
            Treeselect,
            Detail,
            QuickLocationForm,
            QuickSupplierForm,
            JsonExcel,
        },
        data() {
            return {
                operationTitle: 'title.orders',
                filters: {...FILTER_STATE},
                listUrl: 'archives/orders',
                columns: COLUMN_DEFINITION(this),
                show: true,
                dropdowns: {
                    countries: [],
                    dealers: [],
                    locations: [],
                    supplierCarriers: [],
                    clients: [],
                    brands: [],
                    models: [],
                    modelsGroupByTitle: [],
                    configCodes: [],
                    status: [
                        {id: -3, label: this.$t('title.pending')},
                        {id: -2, label: this.$t('title.qualityHoldBlocked')},
                        {id: -1, label: this.$t('title.confirmationOrder')},
                        {id: 1, label: this.$t('title.produced')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    priorities: [
                        {id: 1, label: this.$t('title.high')},
                        {id: 2, label: this.$t('title.medium')},
                        {id: 3, label: this.$t('title.low')}
                    ],
                },
                dealerToDealerLoadFormVisible: false,
                groupedDealerToDealerOrders: [],
                selectedDealerToDealerOrderLength: 0,
                selectAllBrand: false,
            }
        },
        mounted() {
            this.getCountries();
            this.getDealers();
            this.getLocations();
            this.getCurrencies();
            this.getSupplierCarriers();
            this.getBrands();
            this.getModels();
            this.getClients();

            this.$title = this.$t('topBar.navigations.modules.orders')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = `title.${operation}Archive`;
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = _.uniqBy(data, 'label')

                } catch (e) {
                    this.dropdowns.dealers = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data
                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('archiveorderview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            refreshList() {
                this.loadList(this.listQueryParams)
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.modelsGroupByTitle = _.uniqBy(data.map(item => ({...item, id: item.label})), 'id');
                    this.dropdowns.configCodes = _.uniqBy(data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id,
                        model_label: item.label
                    })), 'id')
                } catch (e) {
                    this.dropdowns.modelsGroupByTitle = []
                    this.dropdowns.configCodes = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            handleSelectModels(changeValue) {
                this.filters.configCodes = []
            },
            handleSelectAllModels(e) {
                this.$nextTick(() => {
                    if (e.target.checked) {
                        let data = _.uniqBy(_.filter(_.uniqBy(this.dropdowns.modelsGroupByTitle, 'label'), ({brand_id}) => _.every([_.includes(this.filters.brands, brand_id)])), 'id');
                        this.filters.modelsGroupByTitle = _.map(data, (i) => i.id)
                    } else {
                        this.filters.modelsGroupByTitle = [];
                    }
                })
            },
            handleSelectBrands(changeValue) {
                this.selectAllBrand = false
                this.filters.modelsGroupByTitle = []
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
